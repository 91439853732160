<template>
  <section>
    <v-carousel
      cycle
      hide-delimiter-background
      :show-arrows="false"
      delimiter-icon="mdi-minus"
      height="420"
    >
      <v-carousel-item
        height="420"
        v-for="(slider, si) in sliders"
        :key="si"
        :src="slider.img"
      ></v-carousel-item>
    </v-carousel>
    <!--产品分类-->
    <v-container text-center class="ctk-div">
      <v-layout wrap>
        <v-flex
          v-for="(cat, hpi) in info.products.category"
          :key="hpi"
          xs12
          md4
          mb-12
          pa-2
          class="ctk-jx-category"
        >
          <v-card
            class="mx-auto ctk-case-category-card"
          >
            <a :href="'/products/'+cat.id">
              <v-img
                :src="cat.logo"
                aspect-ratio="1"
                class="ctk-case-category-img"
              ></v-img>
            </a>
            <div class="ctk-cat-text">
              <h1>{{cat.name}}</h1>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    sliders: [],
    pageId: 0,
    tabProducts: null,
    tabNews: null,
    tabCases: null,
    innerWidth: window.innerWidth,
    detail: null,
    publish: null
  }),
  created () {
    document.querySelector('#menu_home').classList.add('v-btn--active')
    this.getPageData()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        let locale = localStorage.getItem('locale')
        if (!locale) {
          locale = 'cn'
        }
        this.$nextTick(function () {
          this.sliders = val.banner[this.pageId]
        })
        this.info.news = val.news
        this.info.products = {
          category: val.product.category[locale],
          data: val.product.data
        }
        this.info.cases = {
          category: val.cases.category[locale],
          data: val.cases.data
        }
      },
      deep: true
    }
  },
  computed: {
    info () {
      this.$nextTick(function () {
      })
      let sliders = { '0': [] }
      let productCategory = {}
      let products = { 'category': {}, 'data': [] }
      let caseCategory = {}
      let cases = { 'category': {}, 'data': [] }
      let news = { '2': [], '3': [] }
      let locale = localStorage.getItem('locale')
      if (!locale) {
        locale = 'cn'
      }
      /*
      if (this.$store.getters.companyInfo.banner) {
        sliders = this.$store.getters.companyInfo.banner[this.pageId]
      }
     */
      if (this.$store.getters.companyInfo.product) {
        productCategory = this.$store.getters.companyInfo.product.category[locale]
        products = this.$store.getters.companyInfo.product.data
      }
      if (this.$store.getters.companyInfo.cases) {
        caseCategory = this.$store.getters.companyInfo.cases.category[locale]
        cases = this.$store.getters.companyInfo.cases.data
      }
      if (this.$store.getters.companyInfo.news) {
        news = this.$store.getters.companyInfo.news
      }
      return {
        sliders: sliders,
        products: {
          category: productCategory,
          data: products
        },
        cases: {
          category: caseCategory,
          data: cases
        },
        news: news
      }
    }
  },
  methods: {
    getPageData () {
      this.detail = this.$t('detail')
      this.publish = this.$t('publish')
    },
    newsPublishEnter (id) {
      document.getElementById('news_publish_' + id).classList.add('ctk-news-publish-enter')
    },
    newsPublishLeave (id) {
      document.getElementById('news_publish_' + id).classList.remove('ctk-news-publish-enter')
    }
  }
}
</script>

<style>

</style>
